import request, { MarkcoinRequest } from '@/plugins/request'
import {
  RequestGameType,
  BannerListTypeProps,
  RaHallGroupTypeProps,
  RaHallGroupListProps,
  RaHallGameTypeProps,
  RaHallGameListProps,
  GetGameInfoTypeProps,
  RaHallPaginationProps,
  HallGameGroupHotTypeProps,
  HallGameListPaginationProps,
  GameGroupSupplierTypeProps,
  AllGameTypeListReq,
  AllGameTypeListResp,
  GameUrlResp,
} from '@/typings/api/game'

/**
 * banner 列表
 * https://yapi.nbttfc365.com/project/83/interface/api/19019
 */
export const getGameBannerList: MarkcoinRequest<RequestGameType, BannerListTypeProps[]> = () => {
  return request({
    path: '/v1/ra-hall/banner/list',
    method: 'GET',
  })
}

/**
 * 跑马灯公告
 * https://yapi.nbttfc365.com/project/44/interface/api/3002
 */
export const getHorseLampList: MarkcoinRequest = data => {
  return request({
    path: '/v1/helpCenter/horseLamp',
    method: 'POST',
    data,
  })
}

/**
 * 游戏馆类型列表
 * https://yapi.nbttfc365.com/project/83/interface/api/19069
 */
export const getGameGroupTypeList: MarkcoinRequest<RequestGameType, RaHallGroupTypeProps[]> = () => {
  return request({
    path: '/v1/ra-hall/group/type',
    method: 'GET',
  })
}

/**
 * 游戏馆列表
 * https://yapi.nbttfc365.com/project/83/interface/api/19029
 */
export const getGameGroupList: MarkcoinRequest<RequestGameType, RaHallGroupListProps[]> = params => {
  return request({
    path: '/v1/ra-hall/group/list',
    method: 'GET',
    params,
  })
}

/**
 * 游戏类型列表
 * https://yapi.nbttfc365.com/project/83/interface/api/19064
 */
export const getGameTypeList: MarkcoinRequest<RequestGameType, RaHallGameTypeProps[]> = params => {
  return request({
    path: '/v1/ra-hall/game/type',
    method: 'GET',
    params,
  })
}

/**
 * 游戏列表
 * https://yapi.nbttfc365.com/project/83/interface/api/19024
 */
export const getGameTypeData: MarkcoinRequest<RequestGameType, HallGameListPaginationProps> = params => {
  return request({
    path: '/v1/ra-hall/game/list',
    method: 'GET',
    params,
  })
}

/**
 * 进入游戏
 * https://yapi.nbttfc365.com/project/83/interface/api/19054
 */
export const getGameStart: MarkcoinRequest<RequestGameType, GameUrlResp> = params => {
  return request({
    path: '/v1/ra-hall/game/startV2',
    method: 'GET',
    params,
  })
}

/**
 * 推荐的游戏馆
 * https://yapi.nbttfc365.com/project/83/interface/api/19034
 */
export const getHallGameGroupHot: MarkcoinRequest<RequestGameType, HallGameGroupHotTypeProps[]> = () => {
  return request({
    path: '/v1/ra-hall/group/hot',
    method: 'GET',
  })
}

/**
 * 推荐的游戏
 * https://yapi.nbttfc365.com/project/83/interface/api/19039
 */
export const getHallGameHot: MarkcoinRequest<RequestGameType, HallGameListPaginationProps> = params => {
  return request({
    path: '/v1/ra-hall/game/hot',
    method: 'GET',
    params,
  })
}

/**
 * 最近的游戏
 * https://yapi.nbttfc365.com/project/83/interface/api/19044
 */
export const getHallGameRecent: MarkcoinRequest<RequestGameType, RaHallPaginationProps> = params => {
  return request({
    path: '/v1/ra-hall/game/recent',
    method: 'GET',
    params,
  })
}

/**
 * 收藏
 * https://yapi.nbttfc365.com/project/83/interface/api/19049
 */
export const getHallGameCollect: MarkcoinRequest<RequestGameType, RaHallPaginationProps> = params => {
  return request({
    path: '/v1/ra-hall/game/collect',
    method: 'GET',
    params,
  })
}

export const getHallGroupHomeData: MarkcoinRequest = params => {
  return request({
    path: '/v1/ra-hall/group/index',
    method: 'GET',
    params,
  })
}

/**
 * 收藏游戏 - 批量
 * https://yapi.nbttfc365.com/project/83/interface/api/19384
 */
export const getHallGameAllCollect: MarkcoinRequest = data => {
  return request({
    path: '/v1/ra-hall/game/collect',
    method: 'POST',
    data,
  })
}

/**
 * 游戏提供商查询
 * https://yapi.nbttfc365.com/project/83/interface/api/20161
 */
export const getGameGroupSupplier: MarkcoinRequest<RequestGameType, GameGroupSupplierTypeProps[]> = () => {
  return request({
    path: '/v1/ra-hall/game-group-supplier',
    method: 'GET',
  })
}

export const getGameInfoType: MarkcoinRequest<RequestGameType, GetGameInfoTypeProps[]> = params => {
  return request({
    path: '/v1/ra-hall/game/getSupplierGamesInfoByGameTypeId',
    method: 'GET',
    params,
  })
}

export const getGamesInfoByHot: MarkcoinRequest<RequestGameType, GetGameInfoTypeProps[]> = params => {
  return request({
    path: '/v1/ra-hall/game/getSupplierGamesInfoByHot',
    method: 'GET',
    params,
  })
}

export const getGameInfoDetails: MarkcoinRequest = params => {
  return request({
    path: '/v1/ra-hall/game/detail',
    method: 'GET',
    params,
  })
}

/**
 * 所有游戏类型查询（常用功能中）
 */
export const postAllGameTypeList: MarkcoinRequest<AllGameTypeListReq, AllGameTypeListResp[]> = data => {
  return request({
    path: '/v1/ra-hall/game-type/list',
    method: 'POST',
    data,
  })
}

/**
 * 试玩进入游戏
 */
export const getHallGameStartAnonymous: MarkcoinRequest<RequestGameType, GameUrlResp> = params => {
  return request({
    path: '/v1/ra-hall/game/startAnonymous',
    method: 'GET',
    params,
  })
}
